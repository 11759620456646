<template>
  <div class="politicas-container">
    <Menu2></Menu2>
    <Menu></Menu>
    <div class="container-ghost">
        <div class="content-politics">
            <div class="tittle-politics">
                <h4>Políticas de Privacidad y Protección de Datos Personales</h4>
                <p>(Fecha de Última Actualización: 09 de Agosto de 2021)</p>
            </div>
            <div class="politics-paragraph">
                <h6>1.	Disposiciones Generales</h6>
                <p>Sociedad de Educación Altera SPA (“ALTERA ACADEMY”), quiere ofrecerles la mejor experiencia a través de su servicio; por esa razón, nos interesa cuidar la privacidad de nuestros usuarios, poniendo a disposición el presente documento (la “Política”) para informar acerca de las políticas y procedimientos relacionados al tratamiento de la información y datos personales que recopila de usuarios a través del sitio web y/o plataformas virtuales de ALTERA ACADEMY (en adelante de manera conjunta, la “Plataforma”).</p>
                <p>La Política que a continuación se describe está alineada con la Ley No. 19628, (la “Ley de Protección de Datos personales”), por el que se aprueba su Reglamento y la Directiva de Seguridad de la Información (en adelante, “Marco Normativo de Protección de Datos Personales”).</p>
                <p>Los usuarios que accedan a nuestros servicios a través de la Plataforma, aceptan sujetarse a las disposiciones de esta Política, aceptan que se recopile su información personal a través de cuestionarios, registros, formularios electrónicos, entre otros otorgados y/o requeridos virtualmente en la Plataforma, y autorizan a ALTERA ACADEMY a hacer uso y realizar el tratamiento de toda la información que brinde a ALTERA ACADEMY. Cabe mencionar, que la presente política forma parte integrante de los Términos y Condiciones en <a href="https://www.alteraacademy.com/">https://www.alteraacademy.com/terminos</a></p>
                <p>ALTERA ACADEMY se reserva el derecho a modificar la Política de Privacidad, lo cual será comunicado vía correo electrónico con razonable anticipación a su entrada en vigencia.</p>
            </div>
            <div class="politics-paragraph">
                <h6>2. Definiciones</h6>
                <p>Sin perjuicio de los términos definidos en diversas secciones del presente documento, los términos aquí utilizados con la primera letra mayúscula y que no sean definidos de forma diversa, tendrán el significado que a continuación se les atribuye, pudiendo ser utilizados en masculino, femenino o neutro, así como en singular o en plural, indistintamente y según el contexto:</p>
                <p>“Cookie”: Es el archivo de datos que se almacena en el dispositivo del usuario cuando éste tiene acceso al Sitio.</p>
                <p>“Encargado de Tratamiento”: Persona natural o persona jurídica que recopile, almacene, conserve, utilice, bloquee o suprima datos personales de los Usuarios por encargo de ALTERA ACADEMY, en virtud de una relación jurídica que le vincula con el mismo y delimita el ámbito de su actuación.</p>
                <p>“Usuario” y/o “Usuarios”: Aquella persona natural, persona natural con negocio, o persona jurídica que se registran en la Plataforma con la finalidad de adquirir los productos y/o servicios ofrecidos en la Plataforma.</p>
                <p>“Plataforma”: Incluye: (i) el sitio web de Internet <a href="https://www.alteraacademy.com/">https://www.alteraacademy.com</a> y, (ii) las correspondientes aplicaciones para dispositivos móviles. La Política de Privacidad se aplica sólo a la información que los Usuarios nos proporcionan a través de la Plataforma.</p>
                <p>“Servidores Externos”: Usamos otras empresas, agentes o contratistas para que presten servicios externos. Por ejemplo, el procesamiento de pagos y de otras formas de pago, brindan atención al cliente, analizan y mejoran los datos (incluida la información relativa a la interacción de nuestros usuarios con nuestro servicio).</p>
                <p>“Información Personal”: Permite identificar y/o hacer identificable al Usuario; tal como, sus nombres, apellidos, número de DNI, número de teléfono, correo electrónico, entre otros, que ALTERA ACADEMY considere conveniente solicitar, a fin de poder brindar los servicios y/o productos ofrecidos en la Plataforma de manera adecuada.</p>
                <p>“Información General”: Por sí misma, no permite identificar al Usuario, pero que podrá ser requerida por ALTERA ACADEMY para brindar sus servicios.</p>
                <p>En adelante, se entenderá que la expresión “Datos Personales” hará referencia a la Información Personal y a la Información General brindada por los Usuarios.</p>
            </div>
            <div class="politics-paragraph">
                <h6>3. ¿Qué información recolectamos?</h6>
                <p>Si el Usuario desea acceder a los servicios ofrecidos por la Plataforma, deberá registrarse completando el formulario de registro con su Información Personal; incluyendo: su número de teléfono, dirección de correo electrónica, nombres completos, un nombre de usuario, una contraseña.</p>
                <p>Asimismo, recopilamos información del Usuario relacionada al: (i) contenido compartido dentro de la Plataforma, (ii) datos de los cursos (comportamiento y uso del producto, comentarios y proyectos creados), (iii) datos de pago, (iv) datos sobre las compras. ALTERA ACADEMY podrá solicitar otros Datos Personales que considere conveniente en su momento.</p>
                <p>Sin perjuicio de lo detallado líneas arriba, por motivos de seguridad, ALTERA ACADEMY no recopila ni almacena: (i) datos confidenciales del titular de la tarjeta (como todos los números de la tarjeta de crédito o los datos de autenticación de la tarjeta), (ii) datos de los instructores, (iii) datos sobre las cuentas del usuario en otros servicios, salvo estén conectadas a la cuenta de ALTERA ACADEMY.</p>
                <p>ALTERA ACADEMY hace uso de distintas pasarelas de pago para efectos de procesar los cobros relativos al importe de los cursos. La información procesada para generar estas instrucciones de pago corresponde solo a la estrictamente necesaria para dar curso a estas operaciones. Al aceptar esta Política de Privacidad el usuario está de acuerdo en compartir su información con las pasarelas de pago anteriormente señaladas para el propósito acordado. ALTERA ACADEMY no guarda ninguna información respecto a estas operaciones.</p>
                <p>Le damos la opción al Usuario a que pueda añadir mayor información a su perfil. El Usuario puede incluir una foto de perfil, una breve descripción, el país y ciudad donde proviene, su fecha de nacimiento y una imagen de cover.</p>
            </div>
            <div class="politics-paragraph">
                <h6>4. ¿Para qué utilizamos los datos de usuario?</h6>
                <p>ALTERA ACADEMY utilizará la información que recopila para: (i) presentar la Plataforma y sus contenidos; (ii) proporcionar y coordinar con el Usuario la información de los servicios que soliciten; (iii) notificar al Usuario sobre cambios en la Plataforma; (iv) permitir al Usuario participar en funciones interactivas de la Plataforma; (v) brindar soporte al Usuario; (vi) validar la veracidad de la información proporcionada; (vii) actualizar y consultar datos de contacto y otra información relevante; (viii) atender las consultas y solicitudes del Usuario; (ix) gestionar las preferencias de los Usuarios respecto de sus cuentas, (x) realizar estudios estadísticos necesarios para potenciar los servicios de ALTERA ACADEMY y se logre mejorar la experiencia del Usuario; así como tendencias y tráfico, y realizar un seguimiento de las compras y de los datos de uso. (xi) garantizar el cumplimiento de los protocolos de seguridad de la información; entre otras finalidades que se encuentren relacionadas con el servicio brindado por ALTERA ACADEMY al Usuario; y (xii) facilitar el funcionamiento técnico de los servicios, lo que incluye la solución de problemas, la seguridad de los servicios y la prevención del fraude y el uso indebido.</p>
                <p>Salvo que se indique lo contrario en esta sección de nuestra Política, no compartiremos su Información Personal con cualquier tercero sin su autorización, salvo que sea necesario para la ejecución de la relación contractual y para las actualizaciones que se puedan dar en la Plataforma.</p>
                <p>Asimismo, los Datos Personales del Usuario serán utilizados, incluyendo, pero sin limitarse, en los siguientes casos:</p>
                <p>Administración del sistema: Podremos utilizar los Datos Personales del Usuario para los fines de la administración de nuestros sistemas, la asistencia en el diagnóstico de problemas con los servidores, la supervisión del rendimiento y del tráfico de nuestros sistemas, y reunir información demográfica.</p>
                <p>Comunicación y Publicidad: Utilizaremos los Datos Personales para enviar al Usuario periódicamente a su dirección de correo electrónico anuncios, ofertas y enviarle publicidad sobre los productos que se ofrecen en la Plataforma. En caso el Usuario manifieste su consentimiento para el envío de material publicitario no solicitado, dará click en el check box “Acepto expresamente el envío de material publicitario y/o comercial por parte de ALTERA ACADEMY”, el cual se encuentra ubicado al final de la Política. Sin perjuicio de ello, en caso acepte el envío de publicidad, pero luego de ello, quiera retirar el consentimiento, el Usuario podrá solicitar que se omita el envío de publicidad a través del correo electrónico contacto@alteraacademy.com.</p>
                <p>Proveedores de Servicios: En caso decidamos solicitar los servicios de terceros en los que estén involucrados los Datos Personales del Usuario (por ejemplo, servicios de seguridad, pasarela de pagos, gestión y almacenamiento de datos, call centers, entre otros), transferiremos los Datos Personales del Usuario que sean necesarios para que los proveedores puedan llevar a cabo sus servicios, ya sea a empresas proveedores de servicios nacionales o internacionales. Obligándose en dicho caso estos últimos a mantener la confidencialidad sobre la Información a la que tenga o pudieran tener acceso, incluyendo sus empleados o proveedores. Cabe señalar que ALTERA ACADEMY no se hará responsable por las obligaciones que correspondan a los Proveedores de Servicios.</p>
                <p>Otros tipos de divulgación: Sin perjuicio de cualquier disposición en contrario, contenida en esta Política, nos reservamos el derecho, y por la presente, el Usuario nos autoriza expresamente, a compartir su Información Personal o Información General: (1) en respuesta a citaciones, órdenes judiciales o procesos legales o administrativos de cualquier naturaleza, o para establecer, proteger o ejercer nuestros derechos legales o defendernos contra demandas, procedimientos administrativos e investigaciones de cualquier naturaleza; (2) si creemos que es necesario para investigar, prevenir o tomar acción respecto de actividades potencialmente ilegales, fraude, o situaciones que implican amenazas potenciales a la seguridad de cualquier persona o propiedad; (3) si creemos necesario investigar, prevenir o tomar acción respecto del abuso significativo de la infraestructura de la Plataforma, ataques de denegación de servicio, o los intentos de comprometer la seguridad de la Información); y (4) si fuéramos adquiridos o fusionada con otra entidad, y en los casos de reorganizaciones societarias, venta de activos o quiebra.</p>
                <p>Encuestas: Podemos enviar a su correo electrónico y/o a través de mensajes de texto o cualquier otro medio disponible, encuestas sobre los servicios que brindamos a través de la Plataforma, y/o sobre cualquier otro tema de interés para garantizar la mejora de nuestros servicios.</p>
                <p>En virtud a lo anteriormente señalado, ALTERA ACADEMY transferir Información Personal a nivel nacional e internacional de los Usuarios por las finalidades detalladas en el presente punto, para lo cual los Usuarios brindan su consentimiento informado, previo y expreso.</p>
            </div>
            <div class="politics-paragraph">
                <h6>5. Derechos de los Usuarios</h6>
                <p>Los Usuarios podrán acceder, rectificar, oponerse y cancelar sus Datos Personales (“Derechos Arco”), así como los demás derechos concedidos por la Ley, los mismos que se podrán ejercer mediante solicitud dirigida al siguiente correo: contacto@alteraacademy.com</p>
                <p>El proceder de ALTERA ACADEMY ante el ejercicio de los Derechos Arco por parte del Usuario está subordinado a las disposiciones y los plazos que establece el Marco Normativo de Protección de Datos Personales.</p>
                <p>Sin perjuicio de lo anterior, ALTERA ACADEMY podrá conservar determinada información del Usuario que solicita la baja, a fin de que sirva de prueba ante una eventual reclamación contra ALTERA ACADEMY por responsabilidades derivadas del tratamiento de dicha información. La duración de dicha conservación no podrá ser superior al plazo de prescripción legal de dichas responsabilidades.</p>
            </div>
            <div class="politics-paragraph">
                <h6>6. Tratamiento por terceros – Encargados de Tratamiento</h6>
                <p>Toda vez que ALTERA ACADEMY encargue el tratamiento de datos personales a un tercero, o permita el acceso de terceros a sus bancos de datos para la prestación de algún servicio específico (“Encargados de Tratamiento”), ALTERA ACADEMY tendrá en cuenta las siguientes especificaciones:</p>
                <p>Todo tratamiento de los Datos Personales realizado por un tercero distinto de ALTERA ACADEMY deberá estar regulado en un acuerdo con los Encargados de Tratamiento (“Acuerdo con los Encargados de Tratamiento”) que, en el que se establezca expresamente que el tratamiento de los Datos Personales se realizará siguiendo las instrucciones de ALTERA ACADEMY y bajo la regulación del Marco Normativo de Datos. Asimismo, ALTERA ACADEMY establecerá con los Encargados de Tratamiento que, únicamente se tratarán los Datos Personales para los fines autorizados o establecidos en el contrato, por lo que el Encargado del Tratamiento no los utilizará con una finalidad distinta, ni los comunicará indebidamente a otras personas.</p>
                <p>El Acuerdo con los Encargados de Tratamiento deberá estipular las medidas de seguridad que el Encargado del Tratamiento estará obligado a implementar, tales como la protección de los Datos Personales, la devolución o destrucción de los Datos Personales, una vez finalizado el encargo.</p>
                <p>El Acuerdo con los Encargados de Tratamiento deberá establecer que, en caso de incumplimiento por parte del Encargado de Tratamiento, el infractor responderá ante ALTERA ACADEMY y ante las autoridades por todo tratamiento o uso no autorizado de los Datos Personales.</p>
                <p>Si el Encargado del Tratamiento necesita subcontratar con terceros partes de los servicios que se obligó a ofrecer, deberá contar con la autorización previa de ALTERA ACADEMY, y ésta sólo procederá siempre que el subcontratista asuma las mismas obligaciones que el Encargado del Tratamiento.</p>
                <p>El Acuerdo con los Encargados de Tratamiento deberá establecer además obligaciones de confidencialidad respecto a la información a la que accede el Encargado del Tratamiento, manteniéndose dichas obligaciones en vigor aún después de finalizado el plazo de vigencia del contrato.</p>
            </div>
            <div class="politics-paragraph">
                <h6>7. Incorporación de los Datos Personales en un Banco de Datos</h6>
                <p>De acuerdo a lo establecido en el Marco Normativo de Protección de Datos Personales, ALTERA ACADEMY informa a los Usuarios de la Plataforma que la Información Personal del Usuario será incorporada a los bancos de datos de titularidad de ALTERA ACADEMY.</p>
                <p>Los bancos de datos serán almacenados por medio de Servidores Externos y el tiempo de conservación de los datos será de acuerdo a la finalidad de su almacenamiento. Sin perjuicio de ello, mediante la presente se autoriza a ALTERA ACADEMY a conservar determinada información tras el cumplimiento de la finalidad; a fin de que, sirva de prueba ante un eventual reclamo contra ALTERA ACADEMY por responsabilidades derivadas del tratamiento de dicha información.</p>
                <p>En ese sentido, a través de la presente Política el Usuario da su consentimiento expreso para la inclusión de su Información Personal en los mencionados bancos de datos.</p>
            </div>
            <div class="politics-paragraph">
                <h6>8. Sobre las medidas de seguridad</h6>
                <p>ALTERA ACADEMY garantiza la protección de los Datos Personales del Usuario y evita su alteración, pérdida, tratamiento y/o acceso no autorizado, habida cuenta de la naturaleza de los datos almacenados y los riesgos a que están expuestos, todo ello, conforme a lo establecido en el Marco Normativo de Protección de Datos Personales.</p>
                <p>ALTERA ACADEMY emplea diversas medidas de seguridad para evitar accesos no autorizados, malos usos o divulgación con tecnología y procesos utilizados en la industria. Sin perjuicio de ello, ALTERA ACADEMY no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas, así como la indebida utilización de la información obtenida por esos medios, o de cualquier intromisión ilegítima que escape al control de ALTERA ACADEMY y que no le sea imputable.</p>
                <p>ALTERA ACADEMY no se hace responsable de posibles daños o perjuicios que se pudiesen derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo del sistema electrónico, de retrasos o bloqueos en el uso de la Plataforma, motivados por causas ajenas a ALTERA ACADEMY.</p>
                <p>De igual manera, ALTERA ACADEMY no asume responsabilidad alguna por los posibles daños, multas o acciones legales que se pudieran ocasionar con motivo del incumplimiento de las obligaciones de los Servidores Externos contratados para la Plataforma.</p>
                <p>Adicionalmente, la Plataforma de ALTERA ACADEMY puede contener enlaces a otros sitios web, sin embargo, no asume responsabilidad por el contenido de las políticas de privacidad y/o prácticas de otros sitios web. Cuando un Usuario acceda a un enlace que lo redirija a otro sitio web, éste deberá aceptar y sujetarse a la política de privacidad de dicho sitio web.</p>
            </div>
            <div class="politics-paragraph">
                <h6>9. Flujo transfronterizo de datos</h6>
                <p>Los servidores, proveedores y/u ordenadores utilizados por ALTERA ACADEMY para prestar sus servicios podrán estar ubicados fuera del país u bajo otra jurisdicción gubernamental. El Usuario autoriza a ALTERA ACADEMY a transferir, almacenar y procesar sus Datos Personales a nivel internacional (“Flujo Transfronterizo”) de acuerdo a las disposiciones establecidas en Marco Normativo de Protección de Datos Personales, para lo cual manifiesta su consentimiento previo, informado, libre, expreso e inequívoco.</p>
            </div>
            <div class="politics-paragraph">
                <h6>10. Sobre la Veracidad de los Datos Personales</h6>
                <p>Cada Usuario es responsable por la veracidad, exactitud, vigencia y autenticidad de la información suministrada, y se compromete a mantenerla debidamente actualizada. Sin perjuicio de lo anterior, el Usuario autoriza a ALTERA ACADEMY, si así lo considera pertinente, a verificar la veracidad de los Datos Personales facilitados, a través de información obtenida de fuentes de acceso público o de entidades especializadas en la provisión de dicha información.</p>
            </div>
            <div class="politics-paragraph">
                <h6>11. Información Personal solicitada por autoridades públicas</h6>
                <p>ALTERA ACADEMY se compromete a no divulgar o compartir los Datos Personales del Usuario, sin que el Usuario haya prestado el debido consentimiento para ello, con excepción de las solicitudes de información de autoridades públicas en ejercicio de sus funciones y el ámbito de sus competencias; en virtud de órdenes judiciales y/o disposiciones legales.</p>
            </div>
            <div class="politics-paragraph">
                <h6>12. Cookies y otra información</h6>
                <p>Utilizamos la dirección IP del usuario para identificar problemas, administrar la Plataforma e identificar patrones de comportamiento del tráfico. También se utilizan cookies o tecnología similar para poder ofrecerle contenido acorde a sus intereses y para almacenar su contraseña y así el Usuario no tenga que ingresarla nuevamente cada vez que regresa a la Plataforma, es importante mencionar que, empleamos distintos tipos de cookies, herramientas de recopilación de datos, análisis de perfiles y publicidad en línea. En ese sentido, a fin de que cuente con la información a detalle, le solicitamos revisar la Política de Uso de Cookies de ALTERA ACADEMY https://www.alteraacademy.com.</p>
            </div>
            <div class="politics-paragraph">
                <h6>13. Consentimiento</h6>
                <p>Los Usuarios declaran haber leído de manera previa y brindan su consentimiento libre, expreso, inequívoco e informado para que ALTERA ACADEMY realice el tratamiento de sus Datos Personales; asimismo, manifiestan haber sido informados sobre qué información se recopila, la forma en qué se realizará y la finalidad de la recopilación de los Datos Personales.</p>
            </div>
            <div class="politics-paragraph">
                <h6>14. Menores de edad</h6>
                <p>Los Usuarios declaran haber leído de manera previa y brindan su consentimiento libre, expreso, inequívoco e informado para que ALTERA ACADEMY realice el tratamiento de sus Datos Personales; asimismo, manifiestan haber sido informados sobre qué información se recopila, la forma en qué se realizará y la finalidad de la recopilación de los Datos Personales.</p>
                <p>En el supuesto de que ALTERA ACADEMY recopile y realice el tratamiento de los Datos Personales de menores de edad, necesarios e indispensables para el desarrollo de la relación contractual con el Usuario menor de edad; ALTERA ACADEMY informará al titular del dato personal y/o a su tutor legal, sobre las finalidades del tratamiento de los Datos Personales.</p>
            </div>
            <div class="politics-paragraph">
                <h6>15. Actualización de la Política</h6>
                <p>ALTERA ACADEMY se reserva expresamente el derecho a modificar y/o actualizar en cualquier momento la presente Política, de acuerdo a las modificaciones o exigencias de la regulación o por adecuaciones propias del servicio realizadas a criterio de ALTERA ACADEMY.</p>
                <p>Toda modificación y/o actualización producida en la presente Política entrará en vigencia desde su publicación en la Plataforma.</p>
                <p>Asimismo, ALTERA ACADEMY realizará su mayor esfuerzo para notificarle los Usuarios de dichas modificaciones a través de su correo registrado o haciendo una publicación la Plataforma.</p>
            </div>
            <div class="politics-paragraph">
                <h6>16. Cesión de posición contractual</h6>
                <p>El Usuario autoriza expresamente la cesión de esta Política y de los Datos Personales en favor de cualquier persona que (i) quede obligada por la presente Política; y/o (ii) que sea el nuevo responsable de los bancos de datos que contengan los Datos Personales.</p>
                <p>Luego de producida la cesión, ALTERA ACADEMY no tendrá ninguna responsabilidad con respecto de cualquier hecho que ocurra partir de la fecha de la cesión. El nuevo responsable del banco de datos asumirá todas y cada una de las obligaciones de ALTERA ACADEMY establecidas en la presente Política respecto al tratamiento, resguardo y conservación de los Datos Personales.</p>
            </div>
            <div class="politics-paragraph">
                <h6>17. Legislación aplicable</h6>
                <p>Las Políticas de Privacidad se rigen y se interpretan de acuerdo con las leyes vigentes en la Republica de Chile , sujetándose a la competencia y jurisdicción de los Jueces y Tribunales de Santiago de Chile.</p>
            </div>
            <div class="politics-paragraph">
                <h6>18. Contacto</h6>
                <p>ALTERA ACADEMY acepta todo tipo de duda, queja o comentario con respecto a las Políticas de Privacidad. Envíenos un correo contacto@alteraacademy.com o escríbanos a la siguiente dirección: Sociedad de Educación Altera SPA.  Armando Carrera 5218, Ñuñoa, Santiago, Chile.</p>
                <p>Declaro que luego de haber leído la Política de Privacidad, acepto expresamente todos los términos contenidos en ella y además autorizo a que ALTERA ACADEMY me envíe material publicitario y/o comercial. En señal de conformidad, procedo a llenar el formulario de compra de usuario visitante.</p>
            </div>
        </div>
    </div>
    <Footer @search-course="footerSearch($event)"></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'terminos',
  components: {
    Menu,
    Menu2,
    Footer,
  },
  data() {
    return {
    }
  },

};
</script>

<style scoped>
.politicas-container{
  font-family: "Poppins";
}
.container-ghost{
    padding: 8rem 2rem 5rem 2rem;
    background-color: #f2f2f2;;
}
.content-politics{
    background-color: #fff;
    padding: 2rem;
}
.tittle-politics{
  text-align: left;
  color:#525bb7;
}
.tittle-politics p{
  font-size: 14px;
}
.politics-paragraph{
 text-align: justify;
 padding-top: 1rem;
}
.politics-paragraph h6{
  font-weight: bold;
  font-size: 14px
}
.politics-paragraph p{
  font-size: 14px
}
@media (min-width: 992px){
    .content-politics{
        padding: 3rem;
    }
}

</style>